import { Injectable } from '@angular/core';
import { TableAction } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Entity } from '@core/enums/entity.enum';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from '@capturum/api';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { QrCodeService } from '@core/api/qr-code.service';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TableListAction } from '@core/enums/table-list-action.enum';
import { responseData } from '@capturum/builders/core';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BaseListActionsService {
  public executedAction: BehaviorSubject<TableListAction> = new BehaviorSubject<TableListAction>(null);
  public executedAction$: Observable<TableListAction> = this.executedAction.asObservable();

  constructor(
    public http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly ngxPermissionService: NgxPermissionsService,
    private readonly confirmationService: ConfirmationService,
    private readonly notificationService: NotificationService,
    private readonly qrCodeService: QrCodeService,
  ) {}

  public getDeleteEntityTableRowAction<Model>(entity: Entity, apiService: ApiService<Model>): TableAction {
    return {
      label: this.translateService.instant('button.delete'),
      hidden: !this.hasPermission([`dxp.${entity}.manage`]),
      icon: 'fas fa-trash-alt',
      callback: (item) => {
        return this.getDeleteEntityTableRowActionCallback(item, entity, apiService);
      },
    };
  }

  public getDeleteEntityTableRowActionCallback<Model>(
    item: Model | any,
    entity: Entity,
    apiService: ApiService<Model>,
  ): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('dxp.mobile-app.error-detail.confirm-remove.title'),
      message: this.translateService.instant('dxp.mobile-app.error-detail.confirm-remove.description'),
      accept: () => {
        apiService.delete(item.id).subscribe(() => {
          this.notificationService.success(
            this.translateService.instant(`dxp.${entity}.entity_name`),
            this.translateService.instant('list.item_deleted'),
          );
          this.executedAction.next(TableListAction.delete);
        });
      },
    });
  }

  public getQrCodeTableRowAction(): TableAction {
    return {
      label: this.translateService.stream('dxp.button.download-qr-code'),
      hidden: !this.ngxPermissionService.getPermission('dxp.qr-code.show'),
      icon: 'fas fa-qrcode',
      callback: (item) => {
        this.notificationService.info(
          this.translateService.instant('dxp.toast.info.title'),
          this.translateService.instant('dxp.button.download-qr-started'),
        );

        this.qrCodeService
          .downloadA5Sticker(item?.id)
          .pipe(
            responseData,
            switchMap((response) => {
              if (response?.url) {
                return this.http.get(response.url, { responseType: 'blob' });
              }

              return of(null);
            }),
          )
          .subscribe((updatedResponse) => {
            saveAs(updatedResponse, `qr-code-${item?.id}.pdf`);

            this.notificationService.success(
              this.translateService.instant('toast.success.title'),
              this.translateService.instant('dxp.qr-code.download.success'),
            );

            this.executedAction.next(TableListAction.qrGenerate);
          });
      },
    };
  }

  public hasPermission(permissions: string[]): boolean {
    return permissions.some((permission) => {
      return this.ngxPermissionService.getPermission(permission);
    });
  }
}
