import { InfoTablePaginatorComponent } from './../../shared/modules/info-table-paginator/info-table-paginator.component';
import { DateHelper } from '@core/utils/date.helper';
import { ApiService, ListOptions, Meta } from '@capturum/api';
import { CapturumInfoTableComponent, InfoTableColumn } from '@capturum/ui/info-table';
import { TranslateService } from '@ngx-translate/core';
import { InfoTableFilterConfig } from '@shared/modules/info-table-filters/info-table-filter-config.model';
import { Directive, inject, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FilterType, TableAction } from '@capturum/ui/api';
import { debounceTime, distinctUntilChanged, filter, skip, switchMap, takeUntil } from 'rxjs/operators';
import { DestroyBase } from '@core/base/destroy.class';
import { Router } from '@angular/router';
import { FilterUtil } from '../utils/filter-utils';
import { BaseListActionsService } from '@core/services/base-list-actions.service';
import { LazyLoadEventUtils } from '@core/utils/lazy-load-event-utils';
import { LazyLoadEvent } from 'primeng/api';
import { SortDirection } from '@core/enums/ui-general.enum';
import { Store } from '@ngxs/store';
import { Entity } from '@core/enums/entity.enum';
import { GeneralSelectors } from '@store/admin/general/general.selectors';
import { SetEntityListFilters } from '@store/admin/general/general.actions';
import { Paginator } from '@core/models/paginator.model';
import { Farm } from '@core/models/farm.model';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseListReborn<Model> extends DestroyBase {
  @ViewChild(CapturumInfoTableComponent, { static: true }) public infoTable: CapturumInfoTableComponent;
  @ViewChild(InfoTablePaginatorComponent, { static: true }) public appPaginator: InfoTablePaginatorComponent;
  public stateKey: string;
  public tableData: Model[];
  public tableColumns: InfoTableColumn[];
  public filterConfig$: Observable<InfoTableFilterConfig[]>;
  public paginator: Meta['pagination'];
  public filtersForm: UntypedFormGroup;
  public paginationForm: UntypedFormGroup;
  public tableLoad: boolean;
  public tableActions: TableAction[];
  public defaultFilterValue: { [key: string]: any };
  public activeFarm$: Observable<Farm>;
  protected filterConfig: BehaviorSubject<InfoTableFilterConfig[]> = new BehaviorSubject(null);
  protected apiService: ApiService<Model>;
  protected apiOptions: ListOptions;
  protected translateService = inject(TranslateService);
  protected formBuilder = inject(UntypedFormBuilder);
  protected baseRouter = inject(Router);
  protected store = inject(Store);
  protected baseListActionsService = inject(BaseListActionsService);
  private lazyLoadEvent: LazyLoadEvent;

  constructor() {
    super();
    // Inject dependencies services

    // Assign default values
    this.activeFarm$ = this.store?.select(GeneralSelectors.getActiveFarm);
    this.filterConfig$ = this.filterConfig.asObservable();
    this.filtersForm = this.formBuilder?.group({});
    this.paginationForm = this.formBuilder?.group({
      perPage: [this.paginatorState?.per_page || 10],
      page: [this.paginatorState?.current_page || 1],
    });
  }

  private get paginatorState(): Paginator {
    return JSON.parse(localStorage.getItem(this.stateKey))?.paginator;
  }

  public loadTableData(event?: LazyLoadEvent): void {
    this.paginationForm.patchValue({
      page: this.paginatorState?.current_page || 1,
      perPage: this.paginatorState?.per_page || 10,
    });

    if (event) {
      if (event?.sortField && this.appPaginator?.pPaginator) {
        if (event.sortField !== this.lazyLoadEvent?.sortField || event.sortOrder !== this.lazyLoadEvent?.sortOrder) {
          this.apiOptions.page = 1;
          this.paginationForm.patchValue({
            page: this.paginatorState?.current_page,
            perPage: this.paginatorState?.per_page,
          });
          this.lazyLoadEvent = event;
          this.appPaginator.pPaginator.changePage(0);

          return;
        }
      }

      this.lazyLoadEvent = event;
      this.filtersForm?.patchValue(this.filtersForm.value);
    }
  }

  public getTableData(apiOptions?: ListOptions): void {
    this.tableLoad = true;

    this.setSorting();
    this.apiService.index(apiOptions ? apiOptions : this.apiOptions).subscribe((result) => {
      this.tableData = result?.data;
      this.paginator = result?.meta?.pagination;
      this.tableLoad = false;

      this.saveToLocalStorage();
    });
  }

  public fetchFilters(): void {
    this.filtersForm?.valueChanges
      ?.pipe(
        distinctUntilChanged(),
        debounceTime(555),
        switchMap((filters) => {
          return combineLatest([of(filters), this.filterConfig$]);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(([filters, filterConfigs]) => {
        this.executeOnFilter(filters);
        this.filtersWasUpdated(filters, filterConfigs);
      });
  }

  public filtersWasUpdated(filters: any, filterConfigs: InfoTableFilterConfig[]): void {
    const newFilters = LazyLoadEventUtils.parseDateFiltersReborn(this.apiOptions, filters, filterConfigs);

    this.apiOptions = FilterUtil.setApiOptions(this.apiOptions, newFilters, filterConfigs);

    if (this.paginatorState) {
      this.apiOptions = {
        ...this.apiOptions,
        perPage: this.paginatorState?.per_page,
        page: this.paginatorState?.current_page,
      };
    }

    this.getTableData();
  }

  public saveToLocalStorage(): void {
    localStorage.setItem(
      this.stateKey,
      JSON.stringify({
        paginator: this.paginator,
      }),
    );
  }

  public changePage(currentPage: number): void {
    this.apiOptions.page = currentPage + 1;

    this.getTableData();
  }

  public changePerPage(perPage: number): void {
    this.apiOptions.page = 1;
    this.apiOptions.perPage = perPage;

    this.getTableData();
  }

  public onRowClick({ id }: Model & { id: string }): Promise<boolean> {
    return this.baseRouter.navigate([this.baseRouter.url, id]);
  }

  public resetFilter(sortOptions?: { field: string; direction: SortDirection.asc | SortDirection.desc }[]): void {
    if (sortOptions) {
      this.apiOptions.sort = sortOptions;
      this.lazyLoadEvent = {
        sortField: null,
        sortOrder: null,
      };

      this.infoTable.primeNGTable.reset();
    }
  }

  public listenForFarmChange(): void {
    this.activeFarm$.pipe(distinctUntilChanged(), skip(1), takeUntil(this.destroy$)).subscribe((activeFarm) => {
      return this.handleFarmUpdate(activeFarm);
    });
  }

  protected fetchActionsCallback(): void {
    this.baseListActionsService.executedAction$?.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(() => {
      return this.getTableData();
    });
  }

  protected setSorting(): void {
    if (this.lazyLoadEvent?.sortField) {
      this.apiOptions.sort = [
        {
          field: this.lazyLoadEvent?.sortField,
          direction: this.lazyLoadEvent?.sortOrder === -1 ? SortDirection.desc : SortDirection.asc,
        },
      ];
    }
  }

  protected initFilters(filtersConfigs: InfoTableFilterConfig[]): void {
    this.filterConfig.next(this.getFiltersDefaultValue(filtersConfigs));
  }

  protected executeOnFilter(filters: Record<string, any>, entity?: Entity): void {
    this.store.dispatch(new SetEntityListFilters(entity || FilterUtil.getEntity(this.baseRouter.url), filters));
  }

  protected getSavedFilters(entity: Entity): Record<string, any> {
    return this.store.selectSnapshot(
      GeneralSelectors.getEntityListFilters(entity || FilterUtil.getEntity(this.baseRouter.url)),
    );
  }

  protected getFiltersDefaultValue(filtersConfigs: InfoTableFilterConfig[], entity?: Entity): InfoTableFilterConfig[] {
    const latestFilters = this.getSavedFilters(entity);

    // Set default value
    return filtersConfigs.map((item) => {
      const fieldValue = Object.values(latestFilters).some((value) => {
        return value !== null;
      })
        ? latestFilters[item.field]
        : this.defaultFilterValue
          ? this.defaultFilterValue[item.field]
          : null;

      return {
        ...item,
        value: this.getFilterValue(item.field, fieldValue, filtersConfigs),
      };
    });
  }

  protected getFilterValue(field: string, value: any, filtersConfigs: InfoTableFilterConfig[]): any {
    if (value) {
      const filtersConfig: InfoTableFilterConfig = filtersConfigs.find((filterConfig) => {
        return filterConfig?.field === field;
      });

      if (filtersConfig && filtersConfig.type === FilterType.DATEPICKER) {
        return DateHelper.fromStringToDate(value);
      }
    }

    return value;
  }

  protected handleFarmUpdate(farm?: Farm): void {
    this.getTableData();
  }
}
